export const THEME: string = document.getElementsByTagName('html')[0].getAttribute('data-bs-theme') || 'dark';

export const PRODUCT_LINES: any = ['Energy', 'Wells', 'Maritime', 'Renewables & Utilities'];

export const PRODUCT_NAMES: any = ['Kognitwin', 'Kognitwin Grid', 'Kognitwin Simulation', 'SiteCom', 'SDP', 'Vessel Insight Applications', 'Vessel Insight Infra'];

export const PRODUCT_NAMES_MAPPING: any = {
    'Wells': ['SDP', 'SiteCom'],
    'Energy': ['Kognitwin', 'Kognitwin Simulation'],
    'Maritime': ['Vessel Insight Applications', 'Vessel Insight Infra'],
    'Renewables & Utilities': ['Kognitwin Grid']
};
export const ADAPT_ROLL_OUT_HEADING: any = "ADAPT Rollout - Engineering";
export const ADAPT_ROLL_OUT_STATUS: string[] = ["Done/In Practice", "Ongoing Refinement", "Not Started"];
export class AppConstant {
     public static readonly noTableDataMsg: string = 'No matching records found'; 
    }

export interface IProductMappingRequest {
    teamID: number;
    name: any;
    productID: number;
    contactID: number;
    comments: any;
    isActive: boolean;
    isRolledOut: boolean;
}
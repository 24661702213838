import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SplashScreenService } from './splash-screen.service';
import { ThemeModeService } from '../theme-mode-switcher/theme-mode.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  mode: string = '';
  @ViewChild('splashScreen', { static: true }) splashScreen: ElementRef;

  constructor(private splashScreenService: SplashScreenService, private themeModeService: ThemeModeService) {
    themeModeService.mode.subscribe((data)=> {
    this.mode = data;
    })
}

ngOnInit(): void {
  this.splashScreenService.init(this.splashScreen);
}
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 1,
  clientId: 'e03e60c6-81e9-407a-b3f9-78871214dcc4',
  authority: 'https://login.microsoftonline.com/bf7cb870-b378-42ab-a618-31704bc2e9b7',
  redirectUri: window.location.origin,
  apiUri:'https://api-dev-admincenter.azurewebsites.net'
  // apiUri: 'https://localhost:7027'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

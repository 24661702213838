import { ResolveFn } from '@angular/router';
import { getCSSVariableValue } from './_metronic/kt/_utils';
import { THEME } from './app.constants';

export const AppResolver: ResolveFn<Promise<any>> = (route, state) => {
  //style.css file takes time to load. Hence waiting the page load until css file is loaded
  //How it's done ?
  //trying to fecth a css variable from css file. When the expected value is returned then the route is resolved. 
  return new Promise((resolve, reject) => {
    const intervalInstance = setInterval(() => {
      const cssTheme = getCSSVariableValue('--theme');
      if (THEME === cssTheme) {
        clearInterval(intervalInstance);
        resolve('');
      }
    }, 100)
  })

};
